import LinkTools from '@client/core/utils/LinkTools';
import LinkHandling from '@client/core/PageSwitch/LinkHandling';
import { Debug } from '@client/core/utils/Debug';
import { IAzureAuthConfig, IAzureAuthData } from './IAzureAuthConfig';
import { AzureAuthUser } from './AzureAuthUser';
import { Observable } from '@client/core/utils/Observable';
import { AzureAuthLog } from './AzureAuthLog';

export type AzureAuthStatus = 'authenticating' | 'signed-in' | 'signed-out';

export class AzureAuth {
	public userSignedIn: Observable<IAzureAuthData> = new Observable<IAzureAuthData>();
	public userSignedOut: Observable<{}> = new Observable<{}>();
	public statusChange: Observable<AzureAuthStatus> = new Observable<AzureAuthStatus>();

	private _config: IAzureAuthConfig;
	private _user: AzureAuthUser;

	private _status: AzureAuthStatus = 'signed-out';

	private _log: AzureAuthLog = new AzureAuthLog('[AzureAuth]');

	constructor(config: IAzureAuthConfig) {
		this._config = config;

		this._user = new AzureAuthUser(this._config);

		this._log.enabled = this._config.log === true;

		if (typeof window !== 'undefined') {
			if (this._config.debugTools === true) {
				window.addEventListener('keyup', this.onKey);
			}

			window.addEventListener('focus', this.onFocus);
		}
	}

	private onFocus = async (): Promise<void> => {
		this._log.log('AzureAuth.onFocus();');

		// check that the token still works
		try {
			await this._user.loadAccessToken();
		} catch (e) {
			this._log.warn(e);
			this.signOut();
			return;
		}
	};

	/**
	 * Debug
	 */
	private onKey = (e: KeyboardEvent): void => {
		// this._log.log(e.key);

		if (e.key === 'c') {
			// this._log.log('info in clipboard');
			// navigator.clipboard.writeText(JSON.stringify(this._user.data));
			console.log(JSON.stringify(this._user.data));
		}
	};

	/**
	 * Login
	 */
	public async signIn(rememberPath: boolean = true) {
		if (this._status !== 'signed-out') return;

		this.setStatus('authenticating');
		if (rememberPath) this.setRedirectPage();

		if (Debug.isLocal()) {
			this._log.warn('If the sign in fail in debug mode - you should manually get the code + verifier');

			if (!this._config.testUser) {
				this._log.warn(`this._config.testUser is not set`);
				return;
			}

			this._log.log('Using debug login');
			this._user.setData(this._config.testUser);

			if (this._user.canRetreiveAccessToken() === true) {
				LinkHandling.Instance.openLink(LinkTools.getLink(`auth/login-done?code=${this._user.data.user?.code}`));
			}

			return;
		}

		this._log.log('Starting login flow');

		const loginLink = await this._user.getNewLoginURL();

		window.open(loginLink, '_parent');
	}

	private setStatus(status: AzureAuthStatus): void {
		this._status = status;
		this.statusChange.trigger(this._status);
	}

	/**
	 * Users are redirected here after login
	 */
	public async signInDone(code: string): Promise<void> {
		if (!code) {
			// something went wrong
			this._log.error('Login failed becuase the code is missing');
			this._user.flush();
			return;
		}

		this._log.log('Auth.signInDone();');

		this._user.restore();
		this._user.setData({ user: { code: code } });

		this._log.dir(this._user.data);

		this.setStatus('authenticating');

		try {
			await this._user.loadAccessToken();
		} catch (e) {
			this._log.warn(e);
			this.signOut();
			this.redirectBack();
			return;
		}

		this.setStatus('signed-in');
		this.userSignedIn.trigger(this._user.data);

		this.redirectBack();
	}

	/**
	 * Redirect loop
	 */
	private setRedirectPage(): void {
		localStorage.setItem('auth.sign-in-page', window.location.pathname);
	}

	private redirectBack(): void {
		this._log.log('Auth.redirectBack();');

		// redirect
		let signInFromPage: string | null = localStorage.getItem('auth.sign-in-page');
		localStorage.removeItem('auth.sign-in-page');

		if (!signInFromPage) return;

		if (signInFromPage === window.location.pathname) {
			this._log.log('dont redirect to self');
			return;
		}

		const redirectToPath: string = signInFromPage ? signInFromPage : this._config.defaultRedirectPage;
		this._log.log('redirectToPath : ' + redirectToPath);
		LinkHandling.Instance.openLink(LinkTools.getLink(redirectToPath));
	}

	/**
	 * Logout
	 */
	public signOut(): void {
		this._log.log('');
		this._log.log('Auth.signOut();');

		this._user.signOutCall();
		this._user.flush();

		this.setStatus('signed-out');
		this.userSignedOut.trigger(this._user.data);
	}

	/**
	 * Restore
	 *
	 * called upon sign to check if the token is still valid
	 */
	public async restore() {
		this._log.log('');
		this._log.log('Auth.restore();');

		this._user.restore();
		this._log.dir(this._user.data);

		if (!this._user.canRetreiveAccessToken()) {
			this._log.log('Cannot restore');
			return;
		}

		this._log.log('Trying to restore');

		this.setStatus('authenticating');

		try {
			await this._user.loadAccessToken();
		} catch (e) {
			this._log.warn(e);
			this.signOut();
			return;
		}

		this._log.log('Hours to expire: ' + this._user.secondsToExpire() / 60 / 60);

		this.setStatus('signed-in');
		this.userSignedIn.trigger(this._user.data);
	}

	/**
	 * Safe call
	 */
	public async authenticatedCall(call: () => Promise<Response>): Promise<Response | undefined> {
		this._log.log('');
		this._log.log('Auth.authenticatedCall();');

		try {
			await this._user.loadAccessToken();
		} catch (e) {
			this._log.warn('Abort call because - ' + e);
			this.signOut();

			return undefined;
		}

		// make the call
		let status: number = -1;

		try {
			const result = await call();
			this._log.log(`${result.url} - ${result.status}`);

			status = result.status;

			if (status === 200) {
				return result;
			}
		} catch (e) {
			this._log.warn('Call failed because - ' + e);

			return undefined;
		}

		// force renew token - if the locally cached data is for some reason invalid
		if (status === 401) {
			// auth error try to renew the token
			try {
				await this._user.renewAccessToken();
			} catch (e) {
				this._log.warn('Abort call because - ' + e);
				this.signOut();

				return undefined;
			}
		}

		// make the call again
		try {
			const result = await call();
			this._log.log(`Retrying - ${result.url} - ${result.status}`);

			if (result.status !== 200) {
				this._log.warn('Retried call failed');
			}

			if (result.status === 401) {
				this.signOut();
				return undefined;
			}

			if (result.status !== 200) {
				return undefined;
			}

			return result;
		} catch (e) {
			this._log.warn('Retried call failed because - ' + e);

			return undefined;
		}
	}

	/**
	 * Misc
	 */
	public get user(): AzureAuthUser {
		return this._user;
	}
}
